import "./Assets/scss/style.scss";
import React, { Suspense, lazy, useEffect } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  useLocation,
  Navigate,
} from "react-router-dom";
import Load from "./Components/Load";
import { useLoginStore } from "./Views/Auth/store";
const AddPemilih = lazy(() => import("./Views/Pages/Pemilih/AddPemilih"));
const Login = lazy(() => import("./Views/Auth/Login"));
const Index = lazy(() => import("./Views/Pages/Index"));
// const Dashboard = lazy(() => import("./Views/Pages/Dashboard/Dashboard"));
const Dashboard = lazy(() => import("./Views/Pages/Dashboard/Dashboard1"));
const Pengguna = lazy(() => import("./Views/Pages/Pengguna/Pengguna"));
const TambahPengguna = lazy(() => import("./Views/Pages/Pengguna/AddPengguna"));
const EditPengguna = lazy(() => import("./Views/Pages/Pengguna/EditPengguna"));
const Pemilih = lazy(() => import("./Views/Pages/Pemilih/Pemilih"));
const EditPemilih = lazy(() => import("./Views/Pages/Pemilih/EditPemilih"));
const ImportPemilih = lazy(() => import("./Views/Pages/Pemilih/ImportPemilih"));
const DetailPemilih = lazy(() => import("./Views/Pages/Pemilih/DetailPemilih"));
const Relawan = lazy(() => import("./Views/Pages/Relawan/Relawan"));
const AddRelawan = lazy(() => import("./Views/Pages/Relawan/AddRelawan"));
const EditRelawan = lazy(() => import("./Views/Pages/Relawan/EditRelawan"));
const MasterData = lazy(() => import("./Views/Pages/MasterData/MasterData"));
const Quickcount = lazy(() => import("./Views/Pages/Quickcount1/Quickcount"));
const AddQuickcount = lazy(() =>
  import("./Views/Pages/Quickcount1/AddQuickCountSingle")
);
const Agenda = lazy(() => import("./Views/Pages/Agenda"));
const WaBlast = lazy(() => import("./Views/Pages/Wablast/Wablast"));
const WaBlastDetail = lazy(() => import("./Views/Pages/Wablast/DetailBroadcast"));

const pages = [
  { el: <WaBlast />, url: "wa-blast" },
  { el: <WaBlastDetail />, url: "wa-blast/:id" },
  { el: <Agenda />, url: "agenda" },
  { el: <div>support</div>, url: "support" },
  { el: <div>help</div>, url: "help" },
  { el: <div>privacy</div>, url: "privacy" },
  { el: <div>disclaimer</div>, url: "disclaimer" },

  { el: <Pemilih />, url: "data-pemilih" },
  { el: <AddPemilih />, url: "data-pemilih/add" },
  {
    el: <RoleComp el={<MasterData />} role={["1"]} />,
    url: "master-data",
  },
  { el: <Relawan />, url: "data-relawan" },
  { el: <AddRelawan />, url: "data-relawan/add" },
  { el: <EditRelawan />, url: "data-relawan/edit/:id" },
  {
    el: <RoleComp el={<ImportPemilih />} role={["1"]} />,
    url: "data-pemilih/import-data",
  },
  {
    el: <RoleComp el={<EditPemilih />} role={["1"]} />,
    url: "data-pemilih/edit-pemilih/:id",
  },
  { el: <DetailPemilih />, url: "data-pemilih/detail/:id" },
  { el: <Dashboard />, url: "dashboard" },
  { el: <RoleComp el={<Pengguna />} role={["1"]} />, url: "pengguna" },
  {
    el: <RoleComp el={<TambahPengguna />} role={["1"]} />,
    url: "pengguna/add",
  },
  {
    el: <RoleComp el={<EditPengguna />} role={["1"]} />,
    url: "pengguna/edit/:id",
  },
  {
    el: <RoleComp el={<Quickcount />} role={["1"]} />,
    url: "quickcount",
  },
  {
    el: <RoleComp el={<AddQuickcount />} role={["1"]} />,
    url: "quickcount/add",
  },
];

function RequireAuth({ children }) {
  const [state, action] = useLoginStore();
  const location = useLocation();

  return state.isAuthed === true ? (
    children
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
}

function RoleComp({ el, role }) {
  const [state, action] = useLoginStore();

  return role.includes(state.profile.role) ? (
    el
  ) : (
    <Navigate to="/loged/dashboard" replace />
  );
}

function App() {
  const [state, action] = useLoginStore();

  useEffect(() => {
    action.checkUser();
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<Load />}>
        <Routes>
          <Route
            path="/loged"
            element={
              <RequireAuth>
                <Index />
              </RequireAuth>
            }
          >
            {pages.map((page) => (
              <Route path={page.url} element={page.el} />
            ))}
          </Route>
          <Route path="/" element={<Login />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
